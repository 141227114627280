import Nav from 'components/layout/nav'
import { Menu, Transition } from '@headlessui/react'
import { ArrowLeftCircleIcon, BellIcon, UserIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { userNavigation } from 'configs/header-nav-links'
import useLocalStorage from 'hooks/use-local-storage'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function PrimaryLayout({ children }) {
  return (
    <>
      <div className="flex h-screen bg-newcolorbg">
        <Nav />
        <main className="flex flex-col flex-1 px-6 py-2 overflow-hidden">
          <nav className="bg-transparent py-4">
            <div className="container mx-auto flex justify-between items-center">
              <div className="flex items-center space-x-4">
                {
                  (window.location.pathname !== '' && window.location.pathname !== '/') &&
                  <button
                    type="button"
                    className="relative rounded-full bg-transparent p-1 text-primary"
                  >
                    <ArrowLeftCircleIcon className='h-6 w-6' />
                  </button>
                }
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="text-white"><NotificationsDropdown /></div>
                <div className="text-white"><ProfileDropdown /></div>
              </div>
            </div>
          </nav>
          <div className="flex flex-col flex-1 overflow-y-auto">
            {children}
          </div>
        </main>
      </div>
    </>
  )
}

function NotificationsDropdown() {
  return (
    <button
      type="button"
      className="relative rounded-full bg-transparent p-1 text-gray-400"
    >
      <span className="absolute -inset-1.5" />
      <span className="sr-only">View notifications</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  )
}

function ProfileDropdown() {
  const user = useLocalStorage('user')[0]?.user
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="inline-flex max-w-xs p-2 items-center rounded-full bg-white text-md text-secondary">
          <UserIcon className="h-6 w-6" aria-hidden="true" />
          {user?.firstName} {user?.lastName}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map(item => (
            <Menu.Item key={item.name}>
              {active => (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                    )
                  }
                >
                  {item.name}
                </NavLink>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default PrimaryLayout;
