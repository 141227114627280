import React, { useEffect, useState } from "react";
import { asyncAPI } from "services/reqres/requests";
import requestURLs from '../services/reqres/urls';
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "components/ui-elements/button";
import InputField from "components/ui-elements/input-field";
import Table from "components/ui-elements/table";
import SelectField from "components/ui-elements/select-field";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Users() {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showForm, setShowForm] = useState(null);
    const [userId, setUserId] = useState(null);
    const { user, loading } = useSelector(state => state.auth)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role_id: 1,
    });

    const navigate = useNavigate();

    function handleForbiddenRedirect() {
        navigate('/logout');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getUsers();
                await getRoles();
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault();
        await asyncAPI(formData, requestURLs.register, 'POST', handleForbiddenRedirect);
        closeForm();
        getUsers();
    }

    const handleUpdate = async(e) => {
        e.preventDefault();
        await asyncAPI(formData, requestURLs.register+"/"+userId, 'POST', handleForbiddenRedirect);
        closeForm();
        getUsers();
    }

    const getUsers = async () => {
        const data = await asyncAPI({}, requestURLs.register, 'GET', handleForbiddenRedirect);
        if(data === "Forbidden"){
            navigate("/logout");
        }else{
            setUsers(data.body);
        }
    }

    const getRoles = async () => {
        const data = await asyncAPI({}, requestURLs.getRoles, 'GET', handleForbiddenRedirect);
        if(data === "Forbidden"){
            navigate("/logout");
        }else{
            setRoles(data.body);
        }
    }

    const openForm = () => setShowForm('Add');
    const editForm = (e) => {
        e.preventDefault();
        setShowForm('Edit');
    }
    const closeForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            role_id: '',
        });
        setShowForm(null);
    }

    const viewUser = async(id) => {
        const data = await asyncAPI({}, requestURLs.register+"/"+id, 'GET', handleForbiddenRedirect);
        setUserId(id);
        if(data){
            const { firstName, lastName, email } = data.body;
            setFormData({
            firstName: firstName,
            lastName: lastName,
            email: email,
            });
            setShowForm('View');
        }
    }

    const deleteUser = async(id) => {
        const data = await asyncAPI({}, requestURLs.register+"/"+id, 'DELETE', handleForbiddenRedirect);
        if(data){
            const updatedUsersArray = users.filter(user => user.id !== id);
            setUsers(updatedUsersArray);
        }
    }

    const getRole = (id) => {
        const role = roles.find(item => item.id === id);
        return role ? role.name : 'N/A';
    }

    const columns = [
        { title: 'First Name', dataKey: 'firstName' },
        { title: 'Last Name', dataKey: 'lastName' },
        { title: 'Email', dataKey: 'email' },
        { title: 'Role', dataKey: 'role_id' }
    ]

    const actions = [
        {
            icon: EyeIcon,
            onClick: (item) => viewUser(item.id),
        },
        ...(user && user.role && user.role.permissions["users"] && user.role.permissions["users"].includes("delete")) ? [
            {
              icon: TrashIcon,
              onClick: (item) => deleteUser(item.id),
            },
        ] : [],
    ];

  return (
    <>
      <div className={showForm ? "opacity-50" : ""}>
        <div className="flex justify-between items-center my-2 font-sans">
          <h1 className="text-2xl">Users Master</h1>
          {!loading &&
            <Button
            label={!user?.role?.permissions["users"]?.includes("add") ? "No permission to Add User" : "Add User"}
            theme="orange"
            onClick={openForm}
            disabled={!user?.role?.permissions["users"]?.includes("add") ?? false}
          />}
        </div>
        <Table columns={columns} data={users} actions={actions} parentName={getRole} />
      </div>
      {showForm && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center">
            <div className="fixed right-0 top-0 bg-white shadow-md rounded-lg p-4 w-1/2 h-full">
              <div className="flex justify-between">
                <p>{showForm+' User'}</p>
                <button onClick={closeForm}>Close X</button>
              </div>
              <div className="w-full h-full">
                <form onSubmit={showForm === 'Add' ? handleSubmit : handleUpdate} >
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <InputField
                            label="First Name"
                            name="firstName"
                            className="w-1/2"
                            value={formData.firstName}
                            disabled={showForm === 'View'}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        />
                        <InputField
                            label="Last Name"
                            name="lastName"
                            className="w-1/2"
                            value={formData.lastName}
                            disabled={showForm === 'View'}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                            />
                    </div>
                    <InputField
                        label="Email"
                        name="email"
                        value={formData.email}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    <InputField
                        label="Passowrd"
                        name="password"
                        value={formData.password}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                    <SelectField
                        label="Role"
                        name="role_id"
                        value={formData.role_id}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, role_id: parseInt(e.target.value) })}
                        options={roles}
                                />

                    <div className="flex items-center justify-end mt-4">
                        <Button onClick={closeForm} label={"Cancel"} theme="teal" />
                        {
                            (user && user.role && user.role.permissions["users"] && user.role.permissions["users"].includes("edit")) ?
                                showForm === 'View' ?
                                    <Button onClick={editForm} label={"Edit"} theme="orange" />
                                :
                                    <Button type="submit" label={"Save"} theme="orange" />
                            :   
                                <p className="ml-2">No Edit Access.</p>
                        }
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Users;
