import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
 const pages = [];
 for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
 }

 return (
    <div className="flex justify-center my-4">
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`mx-1 px-3 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md ${currentPage === page ? 'bg-gray-200' : ''}`}
        >
          {page}
        </button>
      ))}
    </div>
 );
};

export default Pagination;