import React, { useState } from 'react';
import Pagination from './pagination';

const Table = ({ columns, data, actions, department, parentName, found, not_found }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / 10);

    const indexOfLastItem = currentPage * 10;
    const indexOfFirstItem = indexOfLastItem - 10;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
    <>
        <table className="w-full text-left font-medium border-solid border-2 border-grey-600 mb-2">
            <thead className="text-xs text-gray-600 bg-yellow-100">
                <tr>
                    {actions && <th className="px-6 py-3">Actions</th>}
                    {columns.map((column, index) => (
                        <th key={index} className="px-6 py-3">{column.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {currentItems.map((item, index) => (
                    <tr key={index} className="border-bottom-solid border-1 border-grey-600">
                        {actions && <td className="px-6 py-3 flex space-x-4">
                            {actions.map((action, actionIndex) => (
                                <div key={actionIndex} className="flex items-center">
                                    <button onClick={() => action.onClick(item)}>
                                        <action.icon className="w-6 h-6" />
                                    </button>
                                </div>
                            ))}
                        </td>}
                        {columns.map((column, colIndex) => (
                            <td key={colIndex} className="px-6 py-3">
                                <p className={found?.includes(item) ? "text-blue-600" : not_found?.includes(item) ? "text-gray-400" : "text-gray-800"}>
                                {column.dataKey === 'department_id' ?
                                    department(item[column.dataKey])
                                :
                                    (column.dataKey === 'parent_id' || column.dataKey === 'location_id' || column.dataKey === 'role_id') ?
                                        parentName(item[column.dataKey])
                                    :
                                        item[column.dataKey]
                                }
                                </p>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange} />
    </>
    );
};

export default Table;