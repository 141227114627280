// GenderCheckbox.js
import React from 'react';

const GenderCheckbox = ({ onChange, value }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">Gender</label>
      <div className="mt-2 flex items-center space-x-4">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            name="gender"
            value="male"
            checked={value === 'male'}
            onChange={onChange}
          />
          <span className="ml-2">Male</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            name="gender"
            value="female"
            checked={value === 'female'}
            onChange={onChange}
          />
          <span className="ml-2">Female</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            name="gender"
            value="other"
            checked={value === 'other'}
            onChange={onChange}
          />
          <span className="ml-2">Other</span>
        </label>
      </div>
    </div>
  );
};

export default GenderCheckbox;
