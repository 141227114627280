import MultiSelectSearchField from 'components/ui-elements/multiSelect';
import React, { useState, useEffect } from 'react'
import { asyncAPI } from 'services/reqres/requests';
import requestURLs from '../services/reqres/urls';
import { useNavigate } from 'react-router-dom';
import SelectField from 'components/ui-elements/select-field';
import Button from 'components/ui-elements/button';
import InputField from 'components/ui-elements/input-field';
import { CheckCircleIcon, EyeIcon } from '@heroicons/react/24/outline';
import Table from 'components/ui-elements/table';
import Spinner from 'components/ui-elements/spinner';

function Locator() {
    const [tabIndex, setTabIndex] = useState(0);
    const [assets, setAssets] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectValues, setSelectedValues] = useState([]);
    const [locationId, setLocationId] = useState('');
    const [searchRoom, setSearchRoom] = useState('');
    const [foundAssets, setFoundAssets] = useState([]);
    const [notFoundAssets, setNotFoundAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [scanning, setScanning] = useState(null);

    const navigate = useNavigate();
    function handleForbiddenRedirect() {
        navigate('/logout');
    };

    const handleTabChange = (newIndex) => {
        setTabIndex(newIndex);
    };

    const getLocations = async () => {
        const data = await asyncAPI({}, requestURLs.getLocations, 'GET', handleForbiddenRedirect);
        const loc_data = data.body.filter(location => !location.children || location.children.length === 0);
        setLocationId(loc_data[0].id)
        setLocations(loc_data);
    }

    const getAssets = async () => {
        const data = await asyncAPI({}, requestURLs.getAssets, 'GET', handleForbiddenRedirect);
        const updatedObject = data.body.map(item => ({
            ...item,
            label: item.name, value: item.id
        }));
        setAssets(updatedObject);
    }

    useEffect(() => {
        const fetchData = async () => {
          try {
            await getLocations();
            await getAssets();
          } catch (error) {
            console.error('Error fetching assets:', error);
          }
        };
      
        fetchData();
    }, []);

    const startScan = async(type = 'asset') => {
    try {
        setLoading(true)
        await fetch('http://localhost:8888/api/telnet')
        .then(function(response) {
             return response.text();
          }).then(function(data) {
            var jsonStrings = data.split('\n');

                var epcValues = new Set()
                
                for (var i = 0; i < jsonStrings.length; i++) {
                    if (i === jsonStrings.length - 1) continue;
                    
                    var jsonObject = JSON.parse(jsonStrings[i]);
                    epcValues.add(jsonObject.epc);
                }
                var epcArray = Array.from(epcValues);
                if(type === 0){
                    const found_data = selectValues.filter(asset => epcArray.some(epc => epc === asset.rfid_tag));
                    setFoundAssets(found_data);
                    setNotFoundAssets(selectValues.filter(asset => !found_data.includes(asset)));
                }else{
                    const found_data = assets.filter((asset) => asset['location_id'] === locationId).filter(asset => epcArray.some(epc => epc === asset.rfid_tag));
                    found_data.forEach(item => {
                        if (!foundAssets.some(asset => asset.id === item.id)) {
                          setFoundAssets(prevState => [...prevState, item]);
                        }
                    });
                    setNotFoundAssets(assets.filter(asset => !foundAssets.includes(asset)));    
                }
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            setScanning(false);
            alert(error);
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        let validInterval;
        if(scanning){
            validInterval = setInterval(() => {
                startScan(tabIndex)
            }, 2000)
        }
        return () => clearInterval(validInterval);
    }, [scanning])
    
    const handleRoomSearch = (e) => {
        e.preventDefault();
        setSearchRoom(e.target.value);
        if(e.target.value.length > 2){
            setLocations(
              locations.filter(location =>
                location.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
        }else{
            if(e.target.value === ''){
                getLocations();
            }
        }
    }

    const columns = [
        { title: 'Name', dataKey: 'name' },
        { title: 'ECN', dataKey: 'rfid_tag' },
        { title: 'Is Inside', dataKey: 'location_id'}
    ];
    
    const actions = [
        {
           icon: EyeIcon,
           onClick: (item) => console.log(item.id)
        }
    ];

    const getParentName = (id) => {
        const parent = locations.find(item => item.id === id);
        return parent ? parent.name : 'N/A';
    }

    const handleScanButton = () => {
        setScanning(prevState => !prevState);
    }

    return (
        <div className="flex h-full">
            <div className="w-2/5 h-full p-2 border-solid border-r-2 border-gray-300">
                <div className="border-2 border-solid border-gray-300 p-1 bg-white">
                    <button
                    type="button"
                    className={`w-1/2 px-6 py-1 ${tabIndex === 0 ? 'text-blue-600 bg-blue-200' : 'text-gray-500 bg-white'}`}
                    onClick={() => handleTabChange(0)}
                    >
                        Find Asset
                    </button>
                    <button
                    type="button"
                    className={`w-1/2 px-6 py-1 ${tabIndex === 1 ? 'text-blue-600 bg-blue-200' : 'text-gray-500 bg-white'}`}
                    onClick={() => handleTabChange(1)}
                    >
                        Audit Locations
                    </button>
                </div>
                <div className={`tab-panel ${tabIndex === 0 ? 'block' : 'hidden'}`}>
                    <div className='w-full'>
                        <MultiSelectSearchField disabled={scanning} options={locationId !== '' ? assets.filter((asset) => asset["location_id"] === parseInt(locationId)) : assets} selectedOption={selectValues} setSelectedOption={setSelectedValues} />
                    </div>
                    <div className='w-full'>
                        <SelectField
                            disabled={scanning === true}
                            label="Location"
                            name="location_id"
                            value={locationId}
                            onChange={(e) => setLocationId(e.target.value)}
                            options={locations} />
                    </div>
                    <div className='flex w-full justify-between mt-4 items-center border-2 border-solid border-gray-300 p-2 bg-white'>
                        <div className=''>
                            <Button label={scanning ? "Stop Scan" : "Start Scan"} disabled={selectValues.length === 0} theme='orange' onClick={handleScanButton} />
                        </div>
                        <div className='flex flex-col'>
                            <div className='text-sm text-gray-300'>
                                Scan Result
                            </div>
                            <div className='text-sm'>
                                {foundAssets.length} Found | {notFoundAssets.length} Not Found
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`tab-panel ${tabIndex === 1 ? 'block' : 'hidden'}`}>
                    <InputField
                        className="mt-4"
                        placeholder="Search Rooms"
                        name="search"
                        value={searchRoom}
                        onChange={handleRoomSearch}
                    />
                    <div className='w-full bg-white border-2 p-3 border-solid border-gray-300 mt-4 rounded-md'>
                        {
                            locations.map((loc) => (
                                <div className={'w-full flex items-center shadow-md mt-2 p-2 rounded-lg cursor-pointer '+ (locationId === loc.id ? 'bg-primary border-2 border-blue-600' : 'bg-newcolorbg border-2 border-primary')  } key={loc.id} onClick={() => setLocationId(loc.id)}>
                                    <div className='w-1/8 mr-2'>
                                        <input type="checkbox" className='rounded-full' checked={locationId === loc.id} />
                                    </div>
                                    <div className='w-7/8 text-sm'>
                                        <p className='text-blue-800'>{loc.name}</p>
                                        <p className='text-gray-400'>{"Floor 1 > Warehouse 1"}</p>
                                        <p className='text-gray-400'>Last Audit {new Date(loc.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(/\//g, '-').replace(',', ' ')}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='flex justify-between mt-4'>
                        <div></div>
                        <Button label={scanning ? "Stop Scan" : "Start Scan"} theme='orange' onClick={handleScanButton} className='mt-4' />
                    </div>
                </div>
            </div>
            <div className='w-3/5 p-2'>
                <Table columns={columns} parentName={getParentName} found={foundAssets} not_found={notFoundAssets} data={locationId !== '' ? assets.filter((asset) => asset["location_id"] === parseInt(locationId)) : assets} actions={actions} />
            </div>
        </div>
    )
}

export default Locator
