import React from 'react';
import Select from 'react-select';

export default function MultiSelectSearchField({options, selectedOption, setSelectedOption}) {

  return (
    <div className="mt-4">
        <label className='block text-sm font-medium text-gray-300'>Asset</label>
        <Select
            placeholder="Search Assets..."
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            isMulti
        />
    </div>
  );
}