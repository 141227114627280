import { prefixBaseURL } from 'utils/services'

const baseURL = 'https://dev.coordinatepro.com/v1'

const requestPaths = {
  login: '/users/authenticate',
  register: '/users',
  user: '/users/:id',
  getLocations:'/locations',
  getDepartments: '/locations/departments',
  getAssets: '/assets',
  getRoles: '/roles'
}

export default prefixBaseURL(requestPaths, baseURL)
