import { forwardRef, useId } from 'react'

const SelectField = forwardRef(({ label, options, error, className, ...selectProps }, ref) => {
    const id = useId() + '-' + selectProps.name;
  
    return (
      <div className={className}>
        <label htmlFor={id} className="mt-2 block text-sm font-medium text-gray-300">
          {label}
        </label>
        <div className="mt-2">
          <select
            id={id}
            ref={ref}
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            {...selectProps}
          >
            {
                selectProps.name === 'parent_id' && <option key="0">None</option>
            }
            {options.map((option) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>
        </div>
        {error && error.message && <div className="text-xs text-red-400 mt-1">{error.message}</div>}
      </div>
    );
});

export default SelectField;
  