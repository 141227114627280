import requestURLs from './urls'
import { login, logout } from 'store/auth'

const asyncRegister =
  ({ firstName, lastName, email, password, client_id }) =>
  async dispatch => {
    const requestURL = requestURLs.register
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName, lastName, email, password, client_id }),
    }
    const response = await fetch(requestURL, requestOptions)
    const data = await response.json()
    if (data.success){
      dispatch(login({ user: data.body, token: data.body.token }))
    }else{
      alert(
        data.error.body[0].message
      )
    }
  }

const asyncLogin =
  ({ email, password, client_id }) =>
  async dispatch => {
    const requestURL = requestURLs.login
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password, client_id }),
    }
    const response = await fetch(requestURL, requestOptions)
    const data = await response.json()
    if (data.success){
      dispatch(login(data.body))
    }else{
      alert(
        data.msg
      )
    }
  }

const asyncLogout = () => async dispatch => {
  return dispatch(logout())
}

const asyncAPI = async( body, requestURL, method, requestCallback ) => {
  const token = JSON.parse(localStorage.getItem('token'));
  let requestOptions = {
    method: method,
    headers: { authorization: token, 'Content-Type': 'application/json' },
  }
  if(method !== 'GET'){
    requestOptions = { ...requestOptions, body: JSON.stringify( body ) }
  }
  const response = await fetch(requestURL, requestOptions)
  const data = await response.json()
  if (!data.success) {
    if(data.message === "Forbidden"){
      requestCallback();
    }else{
      alert(data.msg);
    }
  }else{
    return data
  }
}

export { asyncRegister, asyncLogin, asyncLogout, asyncAPI }
