import React from 'react'
import { ReactComponent as ChartImage } from '../icons/chart.svg';
import Table from 'components/ui-elements/table';

function Home() {
  const columns = [
    { title: 'Department', dataKey: 'department' },
    { title: 'Misplaced', dataKey: 'misplaced' },
    { title: 'Uncalibrated', dataKey: 'uncalibrated' },
  ];

  const rows = [
    { department: 'Lubricants', misplaced: '20%', uncalibrated: '10%' },
    { department: 'Radiology', misplaced: '15%', uncalibrated: '7%' },
    { department: 'Burners', misplaced: '12%', uncalibrated: '5%' },
    { department: 'Fuels', misplaced: '8%', uncalibrated: '3%' },
  ]

  return(
    <div className='flex'>
      <div className='w-1/2 bg-white rounded-lg m-1'>
        <div className='p-3'>
          <div className='flex justify-between'>
            <p className='text-gray-700'>Asset Insight</p>
            <p className='text-blue-400'>All Locations</p>
          </div>
          <div className='flex'>
            <div className='w-1/3 p-2'>
              <p className='text-blue-700 font-bold text-3xl mt-2'>506</p>
              <p className='text-gray-400 mt-1'>Total</p>
              <hr className='border-primary my-4' />
              <p className='text-blue-400 text-lg mt-2'>21</p>
              <p className='text-gray-400 mt-1'>Under Calibration</p>
            </div>
            <div className='w-1/3'>
              <ChartImage />
            </div>
            <div className='w-1/3 p-2 text-end'>
              <p className='text-red-300 font-bold text-xl mt-2'>71</p>
              <p className='text-gray-400 mt-1'>Misplaced</p>
              <hr className='border-primary my-4' />
              <p className='text-primaryBlue text-lg mt-2'>133</p>
              <p className='text-gray-400 mt-1'>Lost / Unkown</p>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-3 mt-4'>
          <p>Grouped Stats</p>
        </div>
        <Table columns={columns} data={rows} />
      </div>
      <div className='w-1/2 m-1'>
        <div className='w-full flex'>
          <div className='w-1/2 bg-white rounded-lg m-1'>
            <div className='p-3'>
              <div className='flex justify-between'>
                <p className='text-gray-700'>Calibrations</p>
                <p className='text-blue-400'>All Locations</p>
              </div>
              <div className='w-full bg-primary rounded-lg flex p-3 m-2 mt-3 justify-between items-center'>
                <div>
                  <p className='text-sm'>Recent</p>
                  <p className='text-sm'>Last 7 days</p>
                </div>
                <div className='text-2xl text-blue-400'>
                  08
                </div>
              </div>
              <div className='w-full bg-gray-200 rounded-lg flex p-3 m-2 justify-between items-center'>
                <div>
                  <p className='text-sm'>Due</p>
                  <p className='text-sm'>in 30 days</p>
                </div>
                <div className='text-2xl text-red-400'>
                  34
                </div>
              </div>
              <div className='w-full bg-gray-200 rounded-lg flex p-3 m-2 justify-between items-center'>
                <div>
                  <p className='text-sm'>Out of Order</p>
                  <p className='text-sm'>in 7 days</p>
                </div>
                <div className='text-2xl text-red-400'>
                  02
                </div>
              </div>
            </div>
          </div>
          <div className='w-1/2 bg-white rounded-lg m-1'>
            <div className='p-3 text-center'>
              <p>Coming Soon...</p>
            </div>
          </div>
        </div>
        <div className='w-full m-1 bg-white rounded-lg'>
          <table className='table w-full bg-white'>
            <thead>
              <tr className='border-b border-b-2 border-gray-200'>
                <th className='p-2 text-left'>Reports</th>
              </tr>
            </thead>
            <tbody>
              <tr className='border-b border-2 border-gray-200'>
                <td className='p-2'>Assets Status Report</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Home
