import React, { useEffect, useState } from "react";
import { asyncAPI } from "services/reqres/requests";
import requestURLs from '../services/reqres/urls';
import { EyeIcon, TableCellsIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "components/ui-elements/button";
import InputField from "components/ui-elements/input-field";
import SelectField from "components/ui-elements/select-field";
import { ReactComponent as BinaryIcon } from '../icons/binary.svg';
import Table from "components/ui-elements/table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Location() {
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showForm, setShowForm] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [parentId, setParentId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [search, setSearch] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    parent_id: 'None',
    department_id: 1,
  });
  const { user, loading } = useSelector(state => state.auth)

  const navigate = useNavigate();
  function handleForbiddenRedirect() {
    navigate('/logout');
  };

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  const getLocations = async () => {
    const data = await asyncAPI({}, requestURLs.getLocations, 'GET', handleForbiddenRedirect);
    setLocations(data.body);
  }

  const getDepartments = async () => {
    const data = await asyncAPI({}, requestURLs.getDepartments, 'GET', handleForbiddenRedirect);
    setDepartments(data.body);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getLocations();
        await getDepartments();
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (formData.parent_id === 'None') {
      delete formData.parent_id;
    }
    await asyncAPI(formData, requestURLs.getLocations, 'POST', handleForbiddenRedirect);
    closeForm();
    getLocations();
  }

  const handleUpdate = async(e) => {
    e.preventDefault();
    if (formData.parent_id === 'None') {
      delete formData.parent_id;
    }
    await asyncAPI(formData, requestURLs.getLocations+"/"+locationId, 'POST', handleForbiddenRedirect);
    closeForm();
    getLocations();
  }

  const openForm = () => setShowForm('Add');
  const editForm = (e) => {
    e.preventDefault();
    setShowForm('Edit');
  }
  const closeForm = () => {
    setFormData({
      name: '',
      parent_id: 'None',
      department_id: 1,
    });
    setShowForm(null);
  }

  const getParentName = (id) => {
    const parent = locations.find(item => item.id === id);
    return parent ? parent.name : 'N/A';
  }

  const getDepartmenName = (id) => {
    const department = departments.find(item => item.id === id);
    return department ? department.name : 'N/A';
  }

  const viewLocation = async(id) => {
    const data = await asyncAPI({}, requestURLs.getLocations+"/"+id, 'GET', handleForbiddenRedirect);
    setLocationId(id);
    if(data){
      const { name, parent_id, department_id } = data.body;
      setFormData({
        name: name,
        parent_id: parent_id,
        department_id: department_id,
      });
      setShowForm('View');
    }
  }

  const deleteLocation = async(id) => {
    const data = await asyncAPI({}, requestURLs.getLocations+"/"+id, 'DELETE', handleForbiddenRedirect);
    if(data){
      const updatedLocationsArray = locations.filter(location => location.id !== id);
      setLocations(updatedLocationsArray);
    }
  }

  const updateParentId = (e) => {
    if(e.target.getAttribute('locid')){
      setParentId(parseInt(e.target.getAttribute('locid')));
    }else{
      setParentId(null)
    }
  }

  function getParentHierarchy(currentId) {
    const currentLocation = locations.find(location => location.id === currentId);

    if (!currentLocation) {
      return [<span key="all" onClick={updateParentId}><button className="text-blue-500 hover:underline">All&nbsp;</button></span>];
    }
  
    const parentHierarchy = [];
  
    if (!currentLocation.parent_id) {
      parentHierarchy.push(<span key="all" onClick={updateParentId}><button className="text-gray-500 hover:underline">All&nbsp;</button></span>);
    } else {
      const parentLocation = locations.find(location => location.id === currentLocation.parent_id);
      if (parentLocation) {
        const parentHierarchyOfParent = getParentHierarchy(parentLocation.id);
        parentHierarchy.push(...parentHierarchyOfParent);
      }
    }
  
    parentHierarchy.push(
      <span key={currentLocation.id}>
        {'> '}
        {parentId !== currentLocation.id ? 
          <button onClick={updateParentId} locid={currentLocation.id} className="text-blue-500 hover:underline">{currentLocation.name}&nbsp;</button>
        :
          currentLocation.name
        }
      </span>
    );
  
    return parentHierarchy;
  }

  function LocationTree({ nodeLocations }) {
    return (
      <div className="flex border-2 border-solid border-grey-600 p-1 bg-white w-full h-full items-center">
        {
          nodeLocations?.map((loc) => (
            (loc.children && loc.children.length > 0) ?
              <button onClick={updateParentId} key={loc.id} className="mr-2">
                <div locid={loc.id} className="p-4 bg-white border-2 w-full border-solid border-grey-600 rounded-lg mr-2 ">
                  {loc.name}
                </div>
              </button>
            :
              <div key={loc.id} className="p-4 bg-white border-2 border-solid border-grey-600 rounded-lg mr-2 ">
                {loc.name}
              </div>
          ))
        }
      </div>
    );
  }

  const columns = [
    { title: 'Name', dataKey: 'name' },
    { title: 'Department', dataKey: 'department_id' },
    { title: 'Is Inside', dataKey: 'parent_id' },
  ];

  const actions = [
    {
       icon: EyeIcon,
       onClick: (item) => viewLocation(item.id),
    },
    ...(user && user.role && user.role.permissions["locations"] && user.role.permissions["locations"].includes("delete")) ? [
      {
        icon: TrashIcon,
        onClick: (item) => deleteLocation(item.id),
      },
   ] : [],
  ];

  const searchLocation = async(e) => {
    e.preventDefault();
    setSearch(e.target.value);
    if(e.target.value.length > 2){
      setLocations(
        locations.filter(location =>
          location.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }else{
      if(e.target.value === ''){
        getLocations();
      }
    }
  }

  return (
    <>
      <div className={showForm ? "opacity-50" : ""}>
        <div className="flex justify-between items-center my-2 font-sans">
          <h1 className="text-2xl">Location Master</h1>
          <InputField
            name="search"
            placeholder="Search Locations"
            className="ml-2 w-1/3"
            onChange={searchLocation}
          />
          <div className="border-2 border-solid border-grey-600 p-1 bg-white">
            <button
              type="button"
              className={`w-7 h-7 p-1 ${tabIndex === 0 ? 'text-blue-600 bg-white' : 'text-gray-500 bg-gray-200'}`}
              onClick={() => handleTabChange(0)}
            >
              <TableCellsIcon className="w-full h-full" />
            </button>
            <button
              type="button"
              className={`w-7 h-7 p-1 ${tabIndex === 1 ? 'text-blue-600 bg-white' : 'text-gray-500 bg-gray-200'}`}
              onClick={() => handleTabChange(1)}
            >
              <BinaryIcon className="w-full h-full" />
            </button>
          </div>
          {!loading &&
            <Button
              label={!user?.role?.permissions["locations"]?.includes("add") ? "No permission to Add Location" : "Add Location"}
              theme="orange"
              onClick={openForm}
              disabled={!user?.role?.permissions["locations"]?.includes("add") ?? false}
            />
          }
        </div>
        <div className={`tab-panel ${tabIndex === 0 ? 'block' : 'hidden'}`}>
          <Table columns={columns} data={locations} actions={actions} department={getDepartmenName} parentName={getParentName} />
        </div>
        <div className={`tab-panel ${tabIndex === 1 ? 'block' : 'hidden'}`}>
          <div className="flex items-center">
            {getParentHierarchy(parentId)}
          </div>
          <LocationTree nodeLocations={locations.filter(location => location.parent_id === parentId)} />
        </div>
      </div>
      {showForm && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center">
            <div className="fixed right-0 top-0 bg-white shadow-md rounded-lg p-4 w-1/2 h-full">
              <div className="flex justify-between">
                <p>{showForm+' Location'}</p>
                <button onClick={closeForm}>Close X</button>
              </div>
              <div className="w-full h-full">
                <form onSubmit={showForm === 'Add' ? handleSubmit : handleUpdate} >
                  <InputField
                    label="Name"
                    name="name"
                    value={formData.name}
                    disabled={showForm === 'View'}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                  <SelectField
                    label="Is Inside"
                    name="parent_id"
                    value={formData.parent_id}
                    disabled={showForm === 'View'}
                    onChange={(e) => setFormData({ ...formData, parent_id: parseInt(e.target.value) })}
                    options={showForm === 'Add' ? locations : locations.filter(location => location.id !== locationId)}
                  />
                  <SelectField
                    label="Department"
                    name="department_id"
                    value={formData.department_id}
                    disabled={showForm === 'View'}
                    onChange={(e) => setFormData({ ...formData, department_id: parseInt(e.target.value) })}
                    options={departments}
                  />

                  <div className="flex items-center justify-end mt-4">
                    <Button onClick={closeForm} label={"Cancel"} theme="teal"/>
                    {
                      (user && user.role && user.role.permissions["locations"] && user.role.permissions["locations"].includes("edit")) ?
                        showForm === 'View' ?
                          <Button onClick={editForm} label={"Edit"} theme="orange" />
                        :
                          <Button type="submit" label={"Save"} theme="orange" />
                      :
                        <p className="ml-2">No Edit Access.</p>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Location;
