import Spinner from 'components/ui-elements/spinner';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

function PrivateRoutes() {
  const { user, token, loading } = useSelector(state => state.auth)
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    setShowLoader(loading)

    if (loading === false && !user) {
      navigate('/login', { state: { from: pathname } });
    }
   
    if (!loading) {
      if (!(pathname === "/" || pathname === "/login" || pathname === '/logout' || pathname === "/scan") && user && token && !(pathname.slice(1) in user.role.permissions)) {
        navigate("/");
        alert("Looks like you've stumbled upon a top-secret page. Unfortunately, you don't have access")
      }
    }
   }, [navigate, pathname, user, loading]);

  if(showLoader){
    return (
      <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
        <Spinner className="h-5 w-5 text-green-500 animate-spin" />
      </div>
    );
  }else{
    return <Outlet />
  }
}

export default PrivateRoutes;
