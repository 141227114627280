import { forwardRef, useId } from 'react'

const Checkbox = forwardRef(({ className, ...inputProps }, ref) => {
  // to avoid any clashes with other inputs on the page
  const id = useId() + '-' + inputProps.name
  return (
      <div className="mt-2">
        <input
          id={id}
          type='checkbox'
          ref={ref}
          className="block rounded-md border-0"
          {...inputProps}
        />
      </div>
  )
})

export default Checkbox
