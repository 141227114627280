import React, { useEffect, useState } from "react";
import { asyncAPI } from "services/reqres/requests";
import requestURLs from '../services/reqres/urls';
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "components/ui-elements/button";
import InputField from "components/ui-elements/input-field";
import SelectField from "components/ui-elements/select-field";
import Table from "components/ui-elements/table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Asset() {
  const [locations, setLocations] = useState([]);
  const [assets, setAssets] = useState([]);
  const [showForm, setShowForm] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [assetId, setAssetId] = useState(null);
  const { user, loading } = useSelector(state => state.auth);
  const [search, setSearch] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    asset_type: '',
    model: '',
    ecn: '',
    serial_number: '',
    manufacturer: '10 µL',
    size: '10 µL',
    range: '0.5- 10 µL',
    channel: '12C',
    rfid_tag: '',
    instrument_status: '',
    location_id: null,
    calibrated_on: '',
    calibration_interval: '',
    due: '',
    as_found: ''
  });

  const navigate = useNavigate();
  function handleForbiddenRedirect() {
    navigate('/logout');
  };

  const handleTabChange = (newIndex) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getLocations();
        await getAssets();
      } catch (error) {
        console.error('Error fetching assets:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (formData.location_id === null && locations.length > 0) {
      const updatedFormData = {
        ...formData,
        location_id: locations[0].id,
      };
      await asyncAPI(updatedFormData, requestURLs.getAssets, 'POST', handleForbiddenRedirect);
    }else{
      await asyncAPI(formData, requestURLs.getAssets, 'POST', handleForbiddenRedirect);
    }
    getAssets();
    resetAsset();
    closeForm();
  }

  const handleUpdate = async(e) => {
    e.preventDefault();
    const response = await asyncAPI(formData, requestURLs.getAssets+"/"+assetId, 'POST', handleForbiddenRedirect);
    if (response){
      getAssets();
      resetAsset();
      closeForm();
    }
  }

  const getLocations = async () => {
    const data = await asyncAPI({}, requestURLs.getLocations, 'GET', handleForbiddenRedirect);
    setLocations(data.body);
  }

  const getAssets = async () => {
    const data = await asyncAPI({}, requestURLs.getAssets, 'GET', handleForbiddenRedirect);
    setAssets(data.body);
  }

  const resetAsset = () => {
    setFormData({
      name: '',
      asset_type: '',
      model: '',
      ecn: '',
      serial_number: '',
      manufacturer: '10 µL',
      size: '10 µL',
      range: '0.5- 10 µL',
      channel: '12C',
      rfid_tag: '',
      instrument_status: '',
      location_id: null,
      calibrated_on: '',
      calibration_interval: '',
      due: '',
      as_found: ''
    });
  }

  const openForm = () => setShowForm('Add');
  const editForm = (e) => {
    e.preventDefault();
    setShowForm('Edit');
  }
  const closeForm = () => {
    resetAsset()
    setShowForm(null);
  }

  const getParentName = (id) => {
    const parent = locations.find(item => item.id === id);
    return parent ? parent.name : 'N/A';
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const viewAsset = async(id) => {
    const data = await asyncAPI({}, requestURLs.getAssets+"/"+id, 'GET', handleForbiddenRedirect);
    setAssetId(id);
    if(data){
      const { name, asset_type, model, ecn, serial_number, manufacturer, size, range, channel, rfid_tag, instrument_status, location_id, calibrated_on, calibration_interval, due, as_found } = data.body;
      setFormData({
        name: name,
        asset_type: asset_type,
        model: model,
        ecn: ecn,
        serial_number: serial_number,
        manufacturer: manufacturer,
        size: size,
        range: range,
        channel: channel,
        rfid_tag: rfid_tag,
        instrument_status: instrument_status,
        location_id: location_id,
        calibrated_on: formatDate(calibrated_on),
        calibration_interval: calibration_interval,
        due: formatDate(due),
        as_found: as_found
      });
      setShowForm('View');
    }
  }

  const deleteAsset = async(id) => {
    const data = await asyncAPI({}, requestURLs.getAssets+"/"+id, 'DELETE', handleForbiddenRedirect);
    if(data){
      const updatedAssetArray = assets.filter(asset => asset.id !== id);
      setAssets(updatedAssetArray);
    }
  }

  const columns = [
    { title: 'Name', dataKey: 'name' },
    { title: 'Manufacturer', dataKey: 'manufacturer' },
    { title: 'Location', dataKey: 'location_id' },
    { title: 'Status', dataKey: 'instrument_status' },
  ];

  const actions = [
    {
       icon: EyeIcon,
       onClick: (item) => viewAsset(item.id),
    },
    ...(user && user.role && user.role.permissions["assets"] && user.role.permissions["assets"].includes("delete")) ? [
      {
        icon: TrashIcon,
        onClick: (item) => deleteAsset(item.id),
      },
   ] : [],
  ];


  const searchAsset = async(e) => {
    e.preventDefault();
    setSearch(e.target.value);
    if(e.target.value.length > 2){
      setAssets(
        assets.filter(asset =>
          asset.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }else{
      if(e.target.value === ''){
        getAssets();
      }
    }
  }

  return (
    <>
      <div className={showForm ? "opacity-50" : ""}>
        <div className="flex justify-between items-center my-2 font-sans">
          <h1 className="text-2xl">Assets Master</h1>
          <InputField
            name="search"
            placeholder="Search Assets"
            className="ml-2 w-1/3"
            onChange={searchAsset}
          />
          {!loading &&
          <Button
            label={!user?.role?.permissions["assets"]?.includes("add") ? "No permission to Add Asset" : "Add Asset"}
            theme="orange"
            onClick={openForm}
            disabled={!user?.role?.permissions["assets"]?.includes("add") ?? false}
          />}
        </div>
        <Table columns={columns} data={assets} actions={actions} parentName={getParentName} />
      </div>
      {showForm && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center">
            <div className="fixed right-0 top-0 bg-white shadow-md rounded-lg p-4 w-1/2 h-full">
              <div className="flex justify-end">
                <button onClick={closeForm}>Close X</button>
              </div>
              <div className="w-full h-full">
                <form onSubmit={showForm === 'Add' ? handleSubmit : handleUpdate} >
                  <div className="mb-4 border-b border-solid border-grey-600">
                    <button
                      type="button"
                      className={`text-lg font-medium mr-4 ${tabIndex === 0 ? 'text-blue-600 border-b border-solid border-primary' : 'text-gray-500'}`}
                      onClick={() => handleTabChange(0)}
                    >
                      Details
                    </button>
                    <button
                      type="button"
                      className={`text-lg font-medium mr-4 ${tabIndex === 1 ? 'text-blue-600 border-b border-solid border-primary' : 'text-gray-500'}`}
                      onClick={() => handleTabChange(1)}
                    >
                      Location
                    </button>
                    <button
                      type="button"
                      className={`text-lg font-medium ${tabIndex === 2 ? 'text-blue-600 border-b border-solid border-primary' : 'text-gray-500'}`}
                      onClick={() => handleTabChange(2)}
                    >
                      Calibration
                    </button>
                  </div>
                  <div className={`tab-panel ${tabIndex === 0 ? 'block' : 'hidden'}`}>
                    <InputField
                      label="Name"
                      name="name"
                      type="text"
                      required
                      value={formData.name}
                      disabled={showForm === 'View'}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <InputField
                      label="Asset Type"
                      name="asset_type"
                      type="text"
                      required
                      value={formData.asset_type}
                      disabled={showForm === 'View'}
                      onChange={(e) => setFormData({ ...formData, asset_type: e.target.value })}
                    />
                    <InputField
                      label="Model Name"
                      name="model"
                      type="text"
                      required
                      value={formData.model}
                      disabled={showForm === 'View'}
                      onChange={(e) => setFormData({ ...formData, model: e.target.value })}
                    />
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <InputField
                        label="ECN"
                        name="ecn"
                        type="text"
                        required
                        className="w-1/2"
                        value={formData.ecn}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, ecn: e.target.value })}
                      />
                      <InputField
                        label="Serial Number"
                        name="serial_number"
                        type="text"
                        required
                        className="w-1/2"
                        value={formData.serial_number}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, serial_number: e.target.value })}
                      />
                    </div>

                    <SelectField
                      label="Manufacturer"
                      name="manufacturer"
                      required
                      value={formData.manufacturer}
                      disabled={showForm === 'View'}
                      onChange={(e) => setFormData({ ...formData, manufacturer: e.target.value })}
                      options={
                        [
                          {id: '10 µL',name: '10 µL'},
                          {id: 'Rainin Pipet Lite XLS',name: 'Rainin Pipet Lite XLS'},
                          {id: '100 µL',name: '100 µL'},
                          {id: '1000 µL',name: '1000 µL'},
                          {id: '1200 µL',name: '1200 µL'},
                          {id: '2 µL',name: '2 µL'},
                          {id: '200 µL',name: '200 µL'}
                        ]
                      }
                    />

                    <div style={{display: 'flex', gap: '1rem'}}>
                      <SelectField
                        label="Size"
                        name="size"
                        required
                        className="w-1/3"
                        value={formData.size}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, size: e.target.value })}
                        options={
                          [
                            {id: '10 µL',name: '10 µL'},
                            {id: 'M10 µL',name: 'M10 µL'},
                            {id: 'v10 µL',name: 'v10 µL'},
                            {id: '10 ML',name: '10 ML'},
                            {id: 'v10 ML',name: 'v10 ML'},
                            {id: '100 µL',name: '100 µL'},
                            {id: 'M100 µl',name: 'M100 µl'},
                            {id: 'v100 µL',name: 'v100 µL'},
                            {id: '1000 µL',name: '1000 µL'},
                            {id: 'v1000 µL',name: 'v1000 µL'},
                            {id: '12.5 µL',name: '12.5 µL'},
                          ]
                        }
                      />
                      <SelectField
                        label="Range"
                        name="range"
                        required
                        className="w-1/3"
                        value={formData.range}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, range: e.target.value })}
                        options={
                          [
                            {id: '0.5- 10 µL',name: '0.5- 10 µL'},
                            {id: '30 - 300 µL',name: '30 - 300 µL'},
                            {id: '0.1 - 3 µL',name: '0.1 - 3 µL'},
                            {id: '2-20 µL',name: '2-20 µL'},
                            {id: '20-200 µL',name: '20-200 µL'},
                            {id: '100- 1000 µL',name: '100- 1000 µL'},
                            {id: '10-100 µL',name: '10-100 µL'},
                            {id: '0.5 -12.5 µL',name: '0.5 -12.5 µL'},
                            {id: 'varies with the tip',name: 'varies with the tip'},
                          ]
                        }
                      />
                      <SelectField
                        label="Channel"
                        name="channel"
                        required
                        className="w-1/3"
                        value={formData.channel}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, channel: e.target.value })}
                        options={
                          [
                            {id: '12C',name: '12C'},
                            {id: '16C',name: '16C'},
                            {id: '1C',name: '1C'},
                            {id: '24C',name: '24C'},
                            {id: '6C',name: '6C'},
                            {id: '8C',name: '8C'},
                            {id: 'FC',name: 'FC'}
                          ]
                        }
                      />
                    </div>

                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <InputField
                        label="RFID"
                        name="rfid_tag"
                        type="text"
                        required
                        className="w-2/3"
                        value={formData.rfid_tag}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, rfid_tag: e.target.value })}
                      />
                      <InputField
                        label="Status"
                        name="instrument_status"
                        type="text"
                        required
                        className="w-1/2"
                        value={formData.instrument_status}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, instrument_status: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className={`tab-panel ${tabIndex === 1 ? 'block' : 'hidden'}`}>
                    <SelectField
                      label="Is Inside"
                      name="location_id"
                      required
                      disabled={showForm === 'View'}
                      value={formData.location_id}
                      onChange={(e) => setFormData({ ...formData, location_id: e.target.value })}
                      options={locations.filter(location => !location.children || location.children.length === 0)}
                    />
                  </div>
                  <div className={`tab-panel ${tabIndex === 2 ? 'block' : 'hidden'}`}>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <InputField
                        label="Last Calibrated On"
                        name="calibrated_on"
                        type="date"
                        required
                        className="w-1/2"
                        value={formData.calibrated_on}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, calibrated_on: e.target.value })}
                      />
                      <InputField
                        label="Calibration Interval"
                        name="calibration_interval"
                        type="text"
                        required
                        className="w-1/2"
                        value={formData.calibration_interval}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, calibration_interval: e.target.value })}
                      />
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <InputField
                        label="Calibration Due Date"
                        name="due"
                        type="date"
                        required
                        className="w-1/2"
                        value={formData.due}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, due: e.target.value })}
                      />
                      <InputField
                        label="Calibration Status"
                        name="as_found"
                        type="text"
                        required
                        className="w-1/2"
                        value={formData.as_found}
                        disabled={showForm === 'View'}
                        onChange={(e) => setFormData({ ...formData, as_found: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-end mt-4">
                    <Button onClick={closeForm} label={"Cancel"} theme="teal" />
                    {
                      (user && user.role && user.role.permissions["assets"] && user.role.permissions["assets"].includes("edit")) ?
                        showForm === 'View' ?
                          <Button onClick={editForm} label={"Edit"} theme="orange" />
                        :
                          <Button type="submit" label={"Save"} theme="orange" />
                      :
                        <p className="ml-2">No Edit Access.</p>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Asset;
