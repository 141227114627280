import React, { useEffect, useState } from "react";
import { asyncAPI } from "services/reqres/requests";
import requestURLs from '../services/reqres/urls';
import Button from "components/ui-elements/button";
import InputField from "components/ui-elements/input-field";
import Checkbox from "components/ui-elements/checkbox";
import { useNavigate } from "react-router-dom";

function Roles() {
    const [roles, setRoles] = useState([])
    const [showForm, setShowForm] = useState('')
    const [selectedRole, setSelectedRole] = useState({
        permissions: {}
    })
    const navigate = useNavigate();
    const [selectedPermission, setSelectedPermission] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
          try {
            await getRoles();
          } catch (error) {
            console.log(error);
          }
        };
      
        fetchData();
    },[]);

    function handleForbiddenRedirect() {
        navigate('/logout');
    };

    const getRoles = async () => {
        const data = await asyncAPI({}, requestURLs.getRoles, 'GET', handleForbiddenRedirect);
        setRoles(data.body);
    }

    const openForm = () => setShowForm('Add');
    const closeForm = () => setShowForm('');

    const selectRole = async(id) => {
        setShowForm('Edit');
        setSelectedPermission(null);
        const role_selected = roles.filter(role => role.id === id)[0];
        setSelectedRole(role_selected);
    }

    const handlePermissionCheckbox = (event) => {
        const { name, checked } = event.target;
        setSelectedRole(prevState => {
            const updatedPermissions = { ...prevState.permissions };
            if (checked) {
              updatedPermissions[name] = roles.filter(role => role.id === selectedRole.id)[0].permissions[name] || [];
            } else {
              delete updatedPermissions[name];
            }
            return { ...prevState, permissions: updatedPermissions };
        });
    };

    const handleSetPermission = (permission) => {
        setSelectedPermission(permission)
    }

    const handlePermissionChild = (event) => {
        const { name, checked } = event.target;
        const permissionType = selectedPermission;

        setSelectedRole(prevState => {
            const updatedPermissions = { ...prevState.permissions };
            if (updatedPermissions[permissionType]) {
                if (checked) {
                    if (!updatedPermissions[permissionType].includes(name)) {
                        updatedPermissions[permissionType].push(name);
                    }
                } else {
                    updatedPermissions[permissionType] = updatedPermissions[permissionType].filter(permission => permission !== name);
                }
            } else {
                updatedPermissions[permissionType] = checked ? [name] : [];
            }
            return { ...prevState, permissions: updatedPermissions };
        });
    }
    
    const savePermission = async() => {
        const data = await asyncAPI({permissions: selectedRole.permissions}, requestURLs.getRoles+"/"+selectedRole.id, 'POST', handleForbiddenRedirect);
        closeForm();
    }

    return(
        <div className="flex h-full">
            <div className={`${showForm ? 'w-1/3' : 'w-full'} bg-white h-full p-2 border-solid border-2 border-grey-600`}>
                <div className="flex justify-between items-center mb-4 font-sans">
                    <InputField
                        placeholder="Search" />
                    <Button
                        label={"+ Add New Role"}
                        theme="white"
                        onClick={openForm}
                    />
                </div>
                <div className="flex flex-col gap-4 items-center">
                    {roles.map((role) => (
                        <button onClick={() => selectRole(role.id)} key={role.id} className="w-full p-3 border-2 border-solid border-grey-400 rounded-md shadow-md">
                            <p className="text-tcolor text-left">{role.name}</p>
                            <div className="flex justify-between text-xs text-grey">
                                <p>{role.updated_at}</p>
                                <p>Users: 2</p>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className={showForm ? "w-2/3 bg-white h-full p-2 border-solid border-2 border-grey-600" : "hidden"}>
            {selectedRole && (
                <div className="flex flex-col h-full">
                    <div className="flex h-full">
                        <div className={`${selectedPermission ? "w-1/2 " : "w-full " }flex p-4 border-r-2 border-solid border-grey-400`}>
                            <div className="w-full">
                                <div className="w-full flex items-center justify-between">
                                    <span onClick={() => handleSetPermission("locations")} className="block text-sm font-medium mt-2 cursor-pointer">
                                        Locations Master
                                    </span>
                                    <Checkbox
                                        name="locations"
                                        onChange={handlePermissionCheckbox}
                                        checked={selectedRole.permissions?.locations ?? false}
                                    />
                                </div>
                                <div className="w-full flex items-center justify-between">
                                    <span onClick={() => handleSetPermission("assets")} className="block text-sm font-medium mt-2 cursor-pointer">
                                        Assets Master
                                    </span>
                                    <Checkbox
                                        name="assets"
                                        onChange={handlePermissionCheckbox}
                                        checked={selectedRole.permissions?.assets ?? false}
                                    />
                                </div>
                                <div className="w-full flex items-center justify-between">
                                    <span onClick={() => handleSetPermission("users")} className="block text-sm font-medium mt-2 cursor-pointer">
                                        Users Master
                                    </span>
                                    <Checkbox
                                        name="users"
                                        onChange={handlePermissionCheckbox}
                                        checked={selectedRole.permissions?.users ?? false}
                                    />
                                </div>
                                <div className="w-full flex items-center justify-between">
                                    <span onClick={() => handleSetPermission("roles")} className="block text-sm font-medium mt-2 cursor-pointer">
                                        Roles Master
                                    </span>
                                    <Checkbox
                                        name="roles"
                                        onChange={handlePermissionCheckbox}
                                        checked={selectedRole.permissions?.roles ?? false}
                                    />
                                </div>
                            </div>
                        </div>
                        {selectedPermission &&
                        <div className={"w-1/2 flex flex-col p-4"}>
                            <div className="w-full flex items-center justify-between">
                                <span className="block text-sm font-medium mt-2">
                                    {"Add " + selectedPermission}
                                </span>
                                <Checkbox
                                    name="add"
                                    checked={selectedRole.permissions[selectedPermission]?.includes("add") ?? false}
                                    onChange={handlePermissionChild}
                                />
                            </div>
                            <div className="w-full flex items-center justify-between">
                                <span className="block text-sm font-medium mt-2">
                                    {"Edit " + selectedPermission}
                                </span>
                                <Checkbox
                                    name="edit"
                                    checked={selectedRole.permissions[selectedPermission]?.includes("edit") ?? false}
                                    onChange={handlePermissionChild}
                                />
                            </div>
                            <div className="w-full flex items-center justify-between">
                                <span className="block text-sm font-medium mt-2">
                                    {"Delete " + selectedPermission}
                                </span>
                                <Checkbox
                                    name="delete"
                                    checked={selectedRole.permissions[selectedPermission]?.includes("delete") ?? false}
                                    onChange={handlePermissionChild}
                                />
                            </div>
                        </div>}
                    </div>
                    <div className="w-full flex justify-between items-end items-center p-4 border-t-2 border-solid border-grey-400">
                        <p>Permissions: {selectedRole.name} | {selectedPermission}</p>
                        <div>
                            <Button
                                label="Discard"
                                theme="pink"
                                onClick={closeForm}
                            />
                            <Button
                                label="Save"
                                theme="orange"
                                onClick={savePermission}
                            />
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    )
}

export default Roles;