import { Squares2X2Icon, Cog6ToothIcon, UserGroupIcon, MapPinIcon } from '@heroicons/react/24/outline'
import Spinner from 'components/ui-elements/spinner'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ReactComponent as BinaryIcon } from '../../icons/logo-text.svg';
import { ReactComponent as WifiIcon } from '../../icons/modem-alt.svg';
import { ReactComponent as HomeIcon } from '../../icons/HomeIcon.svg';
import { ReactComponent as CursorArrowRippleIcon } from '../../icons/lock.svg';

function Nav() {
  const { user, loading } = useSelector(state => state.auth)
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(loading);
  }, [loading, user])
  
  return (
    <div className="w-64 bg-gradient-to-b from-primary to-newcolorbg border-r border-2 border-borderColor bg-repeat">
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-center h-16 bg-transparent text-white">
          <Logo />
        </div>
        <div className="flex-1 overflow-y-auto mt-6">
          <nav className="mt-6 px-6">
          {
            showLoader ?
              <Spinner className="h-5 w-5 text-green-500 animate-spin" />
            :
              <NavLinks role={user?.role} />
          }
          </nav>
        </div>
        <hr className="border-secondary mx-4" />
        <div className="flex items-center justify-center h-16 text-white">
          <a key="setting" href="/settings" className="flex px-4 py-4 text-secondary hover:bg-hover hover:text-white"><Cog6ToothIcon className="block h-6 w-6 mr-4" aria-hidden="true" /> Settings</a>
        </div>
      </div>
    </div>
  )
}

export default Nav

function NavLinks({ role }) {
  const { pathname } = useLocation()
  return (
    <div className="hidden md:block">
      <a key="home" href="/" className={`flex px-4 py-3 mb-1 text-secondary hover:bg-hover hover:text-primaryBlue  ${pathname === '/' ? "bg-hover text-primaryBlue" : ""}`}><HomeIcon className="block h-6 w-6 mr-4" aria-hidden="true" />Home</a>
      {
        role ?
          Object.keys(role.permissions).map((key) => {
            let IconComponent;
            switch (key) {
              case 'locations':
                IconComponent = WifiIcon;
                break;
              case 'assets':
                IconComponent = Squares2X2Icon;
                break;
              case 'roles':
                IconComponent = CursorArrowRippleIcon;
                break;
              default:
                IconComponent = UserGroupIcon;
            }
    
            return (
              <a key={key} href={`/${key}`} className={`flex px-4 py-3 mb-1 rounded-lg text-secondary hover:bg-hover hover:text-primaryBlue ${pathname.slice(1) === key ? "bg-hover text-primaryBlue" : ""}`}>
                <IconComponent className="block h-6 w-6 mr-4" aria-hidden="true" />
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </a>
            );
          })
        :
          <Spinner className="h-5 w-5 text-green-500 animate-spin" />
      }
      <a key="scan" href="/scan" className={`flex px-4 py-3 text-secondary hover:bg-hover hover:text-primaryBlue  ${pathname === '/scan' ? "bg-hover text-primaryBlue" : ""}`}><MapPinIcon className="block h-6 w-6 mr-4" aria-hidden="true" />Locator</a>
      {/* // <><a key="locate" href="/locations" className="flex px-4 py-4 text-secondary hover:bg-hover hover:text-white"><WifiIcon className="block h-6 w-6 mr-4" aria-hidden="true" />Locations</a><a key="master" href="/assets" className="flex px-4 py-4 text-secondary hover:bg-hover hover:text-white"><Squares2X2Icon className="block h-6 w-6 mr-4" aria-hidden="true" />Assets</a><a key="users" href="/users" className="flex px-4 py-4 text-secondary hover:bg-hover hover:text-white"><UserGroupIcon className="block h-6 w-6 mr-4" aria-hidden="true" />Users</a><a key="roles" href="/roles" className="flex px-4 py-4 text-secondary hover:bg-hover hover:text-white"><CursorArrowRippleIcon className="block h-6 w-6 mr-4" aria-hidden="true" />Roles</a></> */}
    </div>
  )
}

function Logo() {
  return (
    <div className="flex-shrink-0">
      <BinaryIcon />
    </div>
  )
}
