import useAutoLogin from 'hooks/use-auto-login'
import useYupValidationResolver from 'hooks/use-yup-validation-resolver'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import loginBack from '../icons/loginBg.png'

import InputField from 'components/ui-elements/input-field'

import { loginSchema } from 'configs/yup-validation-schemas'

import { asyncLogin } from 'services/reqres/requests'
import Button from 'components/ui-elements/button'

function Login() {
  const dispatch = useDispatch()

  const resolver = useYupValidationResolver(loginSchema)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver })

  const onSubmit = data => {
    dispatch(asyncLogin({ email: data.email, password: data.password, client_id: 2 }))
  }

  useAutoLogin()

  return (
    <div className='flex h-full items-center'>
      <img className='w-1/2 lg:h-full' src={loginBack} />
      <div className='w-1/2 h-full flex flex-col justify-center items-center '>
        <div className="mx-2">
          <h5 className="mt-10 text-center text-2xl leading-9 my-2 tracking-tight text-gray-900">
            Login to your <span className='text-primaryBlue'>coordinatepro</span> account
          </h5>
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6">
            <InputField
              name="email"
              type="email"
              autoComplete={'current-email'}
              placeholder="Email Address"
              error={errors.email}
              {...register('email')}
            />

            <InputField
              name="password"
              type="password"
              placeholder="Password"
              autoComplete={'current-password'}
              error={errors.password}
              {...register('password')}
            />

            <div className='w-full'>
              <div className='text-end text-sm text-gray-400'>
                <Link to="">Forgot Password?</Link>
              </div>
            </div>

            <div className='w-full'>
              <Button
                label="Login"
                theme='orange'
                type="submit"
                wFull
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
